<template>
  <fragment>
    <section class="uk-section-secondary uk-section">
      <div class="uk-container">
        <div
          class="uk-grid-margin uk-grid"
          uk-grid
        >
          <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m uk-first-column">
            <div class="uk-panel uk-width-1-1">
              <div class="uk-margin uk-text-left@m uk-text-center">
                <router-link to="/">
                  <img
                    src="../assets/Logo-White.svg"
                    uk-svg
                    width="120"
                    alt="White Logo"
                  />
                </router-link>
              </div>
            </div>
          </div>
          <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m">
            <div class="uk-panel uk-width-1-1">
              <div class="uk-text-center uk-text-small">
                © 2020 Dajalum AB, All rights reserved
              </div>
            </div>
          </div>
          <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m">
            <div class="uk-panel uk-width-1-1">
              <div class="uk-margin uk-text-right@m uk-text-center">
                <div
                  class="uk-child-width-auto uk-grid-small uk-flex-right@m uk-flex-center uk-grid"
                  uk-grid
                >
                  <div class="uk-first-column">
                    <a
                      class="uk-link-muted uk-icon"
                      href="https://facebook.com/dajalum"
                    ><i class="fab fa-facebook" /></a>
                  </div>
                  <div class="uk-first-column">
                    <a
                      class="uk-link-muted uk-icon"
                      href="https://twitter.com/dajalum"
                    ><i class="fab fa-twitter" /></a>
                  </div>
                  <div>
                    <a
                      class="uk-link-muted uk-icon"
                      href="https://www.instagram.com/dajalum_ab/"
                    ><i class="fab fa-instagram" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>

<script>
export default {
  name: "Footer",
  mounted() {
    //
  },
  methods: {}
};
</script>