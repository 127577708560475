<template>
  <fragment>

    <section
      class="uk-section-default uk-section uk-flex uk-flex-middle"
      style="min-height: calc(100vh - 80px)"
    >
      <div class="uk-width-1-1">
        <div class="uk-container">
          <div
            class="uk-grid-large uk-grid-margin-large"
            uk-grid
          >
            <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m uk-first-column">
              <div class="uk-panel uk-width-1-1">
                <h1 class="uk-h1 uk-width-xlarge uk-margin-auto uk-text-left@m uk-text-center">We make the web beautiful</h1>
                <div class="uk-margin uk-text-left@m uk-text-center">We know what is trending,<br>so lets work with your website! 🤩</div>
                <div class="uk-margin-large uk-text-left@m uk-text-center">
                  <a
                    href="#services"
                    uk-scroll
                    class="uk-button uk-button-primary uk-border-pill"
                  >Lets start</a>
                </div>
              </div>
            </div>
            <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m uk-visible@m">
              <div
                class="uk-margin uk-text-center uk-scrollspy-inview uk-animation-slide-right dajaHero"
                uk-scrollspy-class="uk-animation-slide-right"
              >
                <img
                  alt="Hero 1"
                  title="Hero 1"
                  style="max-height: 70vh"
                  src="../assets/hero-1.svg"
                >
                <img
                  alt="Hero 2"
                  title="Hero 2"
                  style="max-height: 70vh"
                  src="../assets/hero-2.svg"
                >
                <img
                  alt="Hero 3"
                  title="Hero 3"
                  style="max-height: 70vh"
                  src="../assets/hero-3.svg"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="projects"
      class="uk-section-muted uk-section uk-section-large"
    >
      <div class="uk-container">
        <div
          class="uk-grid-margin uk-grid-stack"
          uk-grid
        >
          <div class="uk-flex-auto uk-width-1-1@m uk-first-column">
            <h2 class="uk-h1 uk-margin-remove-top uk-margin-auto uk-text-center@m uk-text-center">Our selected projects</h2>
            <div class="uk-margin uk-width-xlarge uk-margin-auto uk-text-center">We build modern and high qualitative websites with a design that your satisfied customers would love to see. All websites are mobile-friendly.</div>
          </div>
        </div>
        <div
          class="uk-grid-margin"
          uk-grid
          uk-height-match="target: .uk-card; row: false"
        >
          <div
            v-for="(p, index) in projects"
            :key="index"
            :class="p.class"
            class="uk-flex-auto uk-first-column"
          >
            <div
              class="uk-inline-clip uk-transition-toggle uk-box-shadow-medium selectedProducts"
              tabindex="0"
            >
              <img
                :alt="p.desc"
                :title="p.name"
                uk-img
                :src="p.img"
              >
              <div class="uk-transition-fade uk-position-cover uk-overlay uk-overlay-primary">
                <span class="uk-badge productBadge">{{ p.type }}</span>
                <p class="uk-h3 uk-margin-remove-top">{{ p.name }}</p>
                <p>{{ p.desc }}</p>
                <a
                  :href="p.website"
                  class="uk-button uk-button-primary uk-border-pill"
                >Visit website</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="uk-section-secondary">
      <div
        data-src=""
        data-sizes="(max-aspect-ratio: 2560/943) 271vh"
        uk-img
        class="uk-background-norepeat uk-background-cover uk-background-center-center uk-section uk-section-large"
      >
        <div class="uk-container">
          <div
            class="uk-grid-margin uk-grid uk-grid-stack"
            uk-grid
          >
            <div class="uk-width-1-1@m uk-first-column">
              <h3 class="uk-h3 uk-margin-medium uk-margin-auto uk-text-center">
                Do you have your own website idea or would you like consulting free of charges?
              </h3>
              <div class="uk-margin uk-text-center">
                <a
                  class="uk-button uk-button-text"
                  title="Lets explore"
                  uk-scroll
                  href="#contact"
                >Lets talk!</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="uk-section-default uk-section uk-section-large">
      <div class="uk-container">
        <div
          class="uk-grid-margin uk-grid-stack"
          uk-grid
        >
          <div class="uk-flex-auto uk-width-1-1@m uk-first-column">
            <h2 class="uk-h1 uk-text-center">Our focus</h2>
          </div>
        </div>
        <div
          class="uk-grid-margin"
          uk-grid
        >
          <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m uk-first-column">
            <div class="uk-panel uk-width-1-1">
              <div class="uk-panel uk-margin-remove-first-child uk-margin uk-width-medium uk-margin-auto uk-text-center">
                <h3 class="uk-margin-top uk-margin-remove-bottom">Mobile-friendly</h3>
                <div class="uk-panel uk-margin-top">We build websites that are responsive so it adapt with different screen sizes.</div>
              </div>
              <div class="uk-panel uk-margin-remove-first-child uk-margin-large uk-width-medium uk-margin-auto uk-text-center">
                <h3 class="uk-margin-top uk-margin-remove-bottom">Modern</h3>
                <div class="uk-panel uk-margin-top">Like fashion, it comes new trends within web design every year. We make sure that your website is up to date and attractive for the eye of the visitors.</div>
              </div>
            </div>
          </div>
          <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m">
            <div class="uk-panel uk-width-1-1">
              <div class="uk-margin">
                <img
                  alt="Our focus"
                  title="Our focus"
                  uk-img
                  src="../assets/middle.svg"
                >
              </div>
            </div>
          </div>
          <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m">
            <div class="uk-panel uk-width-1-1">
              <div class="uk-panel uk-margin-remove-first-child uk-margin-large uk-width-medium uk-margin-auto uk-text-center">
                <h3 class="uk-margin-top uk-margin-remove-bottom">Satisfied customers</h3>
                <div class="uk-panel uk-margin-top">We always prioritize and work in line with your business need. We want you to win customers!</div>
              </div>
              <div class="uk-panel uk-margin-remove-first-child uk-margin uk-width-medium uk-margin-auto uk-text-center">
                <h3 class="uk-margin-top uk-margin-remove-bottom">Quality</h3>
                <div class="uk-panel uk-margin-top">Your website will be a high qualitative platform for your customers to enjoy. Robust platform, very user-friendly and high resolution logos and images.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="services"
      class="uk-section-muted uk-section uk-section-large"
    >
      <div class="uk-container">
        <div
          class="uk-grid-margin uk-grid-stack"
          uk-grid
        >
          <div class="uk-flex-auto uk-width-1-1@m uk-first-column">
            <h2 class="uk-h1 uk-margin-remove-top uk-width-xlarge uk-margin-auto uk-text-center@m uk-text-center">We'll make it simple for you</h2>
            <div class="uk-margin uk-width-xlarge uk-margin-auto uk-text-center">To make it simple for your convenience, we have pre-made three different choices for you.</div>
          </div>
        </div>
        <div
          class="uk-margin-large uk-grid-stack"
          uk-grid=""
        >
          <div class="uk-flex-auto uk-width-1-1@m uk-first-column">
            <div class="uk-margin uk-text-center">
              <div
                class="uk-child-width-1-1 uk-child-width-1-3@m uk-grid-match uk-grid"
                uk-grid
              >
                <div class="uk-first-column">
                  <div class="uk-card uk-card-default uk-card-large uk-box-shadow-hover-large">
                    <div class="uk-card-media-top">
                      <img
                        alt="Service Basic"
                        title="Basic"
                        uk-img
                        src="../assets/basic.svg"
                      >
                    </div>
                    <div class="uk-card-body uk-margin-remove-first-child">
                      <h3 class="uk-card-title uk-margin-top uk-margin-remove-bottom">Basic Website</h3>
                      <div class="uk-panel uk-margin-top">Do you want a clean, simple, user-friendly and beautiful website that is good enough? This is your pick!</div>
                      <div class="uk-margin-top">
                        <a
                          href="#contact"
                          uk-scroll
                          @click="radioMsg = 'Basic'"
                          class="uk-button uk-button-primary uk-border-pill"
                        >I want this</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="uk-card uk-card-default uk-card-large uk-box-shadow-hover-large">
                    <div class="uk-card-media-top">
                      <img
                        alt="Service Premium"
                        title="Premium"
                        uk-img
                        src="../assets/premium.svg"
                      >
                    </div>
                    <div class="uk-card-body uk-margin-remove-first-child">
                      <h3 class="uk-card-title uk-margin-top uk-margin-remove-bottom">Premium website</h3>
                      <div class="uk-panel uk-margin-top">Here you’ll get everything in the basic package and more! Includes advanced features and extra spice.</div>
                      <div class="uk-margin-top">
                        <a
                          href="#contact"
                          uk-scroll
                          @click="radioMsg = 'Premium'"
                          class="uk-button uk-button-primary uk-border-pill"
                        >Yes! Get me premium!</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="uk-card uk-card-default uk-card-large uk-box-shadow-hover-large">
                    <div class="uk-card-media-top">
                      <img
                        alt="Service App design"
                        title="App design"
                        uk-img
                        src="../assets/appdesign.svg"
                      >
                    </div>
                    <div class="uk-card-body uk-margin-remove-first-child">
                      <h3 class="uk-card-title uk-margin-top uk-margin-remove-bottom">App/Logo design</h3>
                      <div class="uk-panel uk-margin-top">Are you only looking for a cool logo or you have an app idea that need visualization.</div>
                      <div class="uk-margin-top">
                        <a
                          href="#contact"
                          uk-scroll
                          @click="radioMsg = 'App/Logo design'"
                          class="uk-button uk-button-primary uk-border-pill"
                        >Yes! Lets be creative!</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="contact"
      class="uk-section-secondary uk-section uk-section-large"
    >
      <div class="uk-container">
        <div
          class="uk-grid-large uk-grid-margin-large"
          uk-grid
        >
          <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m uk-first-column">
            <div class="uk-panel uk-width-1-1">
              <h3 class="uk-h3">Contact us</h3>
              <form
                v-if="!loadingTxt"
                @submit.prevent="sendEmail"
                method="POST"
              >
                <div class="inputPlaceholder">
                  <input
                    class="uk-input uk-form-large"
                    type="text"
                    name="name"
                    v-model="nameMsg"
                    placeholder=" "
                  >
                  <label>Name*</label>
                </div>
                <div class="inputPlaceholder">
                  <input
                    class="uk-input uk-form-large"
                    type="email"
                    name="_replyto"
                    v-model="emailMsg"
                    placeholder=" "
                  >
                  <label>Mail*</label>
                </div>
                <div class="inputPlaceholder">
                  <textarea
                    class="uk-textarea uk-form-large"
                    type="text"
                    rows="3"
                    name="message"
                    v-model="messageMsg"
                    placeholder=" "
                  />
                  <label>Message (optional)</label>
                </div>
                <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                  <p>I would like to have:</p>
                  <label>
                    <input
                      class="uk-radio"
                      type="radio"
                      name="radio1"
                      value="Basic"
                      v-model="radioMsg"
                    > Basic
                  </label>
                  <label>
                    <input
                      class="uk-radio"
                      type="radio"
                      name="radio1"
                      value="Premium"
                      v-model="radioMsg"
                    > Premium
                  </label>
                  <label>
                    <input
                      class="uk-radio"
                      type="radio"
                      name="radio1"
                      value="App/Logo design"
                      v-model="radioMsg"
                    > App/Logo design
                  </label>
                  <label>
                    <input
                      class="uk-radio"
                      type="radio"
                      name="radio1"
                      value="Other"
                      v-model="radioMsg"
                    > Other
                  </label>
                </div>
                <div
                  v-if="errorTxt"
                  class="uk-alert-danger"
                  uk-alert
                >
                  <p>{{errorTxt}}</p>
                </div>
                <Recaptcha />
                <button
                  type="submit"
                  class="uk-margin-top uk-button uk-button-secondary uk-border-pill"
                >Send</button>
              </form>
              <p
                v-if="loadingTxt"
                class="uk-h4"
              >Thank you for contacting us, we will reply back to you soon!</p>
            </div>
          </div>
          <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m uk-visible@m">
            <div class="uk-panel uk-width-1-1">
              <div
                class="uk-margin uk-text-center uk-scrollspy-inview uk-animation-slide-right"
                uk-scrollspy-class="uk-animation-slide-right"
              >
                <img
                  alt="Contact"
                  title="Contact"
                  style="max-height: 30vh"
                  src="../assets/contact.svg"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="credits">
      <a href="http://www.freepik.com">Designed by Freepik</a>
      <a href="http://www.freepik.com">Designed by stories / Freepik</a>
      <a href="http://www.freepik.com">Designed by slidesgo / Freepik</a>
      <a href="http://www.freepik.com">Designed by pikisuperstar / Freepik</a>
    </section>

  </fragment>
</template>

<script>
import axios from 'axios';
import w1 from '../assets/w1.jpg'
import w2 from '../assets/w2.jpg'
import w3 from '../assets/w3.jpg'
import w4 from '../assets/w4.jpg'
import Recaptcha from '../shared/recaptcha'
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    Recaptcha
  },
  computed: {
    ...mapGetters([ "getVerification" ]),
  },
  data: () => ({
    projects: {
      0: {
        name: "dream:do",
        desc: "Events, sociala media, strategic communication, video production och digital events.",
        img: w1,
        class: "uk-width-2-3@m",
        type: "Premium",
        website: "https://dreamdo.se"
      },
      1: {
        name: "ZelCore",
        desc: "ZelCore is cryptocurrency walllet that holds 200+ assets.",
        img: w2,
        class: "uk-width-1-3@m",
        type: "App Design",
        website: "https://zel.network/project/zelcore"
      },
      2: {
        name: "Escher",
        desc: "Escher Logo and App design",
        img: w3,
        class: "uk-width-1-3@m",
        type: "App Design",
        website: "https://escher.app"
      },
      3: {
        name: "Escher Website",
        desc: "Website",
        img: w4,
        class: "uk-width-2-3@m",
        type: "Premium",
        website: "https://escher.app"
      }
    },
    nameMsg: '',
    emailMsg: '',
    messageMsg: '',
    radioMsg: 'Basic',
    loadingTxt: false,
    errorTxt: "",
  }),
  methods: {
    sendEmail() {
      if (!this.nameMsg) {
        return this.errorTxt = "Please type your name"
      }
      if (!this.emailMsg) {
        return this.errorTxt = "Please type your email"
      }
      if (!this.getVerification) {
        return this.errorTxt = "reCAPTCHA not done!"
      }
      if (this.nameMsg && this.emailMsg) {
        this.loadingTxt = true;
      }
      axios.post('https://formspree.io/mwkrpggk', {
        name: this.nameMsg,
        from: this.emailMsg,
        _subject: `${this.nameMsg} | Message from Dajalum`,
        message: this.messageMsg,
        option: this.radioMsg
      },
      ).then(() => {
        this.nameMsg = '';
        this.emailMsg = '';
        this.messageMsg = '';
      }).catch((error) => {
        if (error.response) {
          this.errorText = error.response
        }
      });
    },
  }
}
</script>